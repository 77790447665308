/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { List } from 'immutable';
import { Credential } from '@nettoken/models';
import { CredentialFilter, GroupFilter, TYPE_JSON } from 'main/import/common';

/**
 * Check if the file contents are a potential Nettoken archive.
 *
 * @param {object} file See `openExportedArchiveFile()` for signature.
 * @param {boolean} [encrypted]
 *
 * @returns {boolean}
 */
const isSupportedFile = (file, encrypted) => {
  const { data, type } = file;
  if (!encrypted) return type === TYPE_JSON && data.groups && data.version;
  const hasNettokenKeys = Object.keys(data).length === 1 && !!data.data;
  return type === TYPE_JSON && hasNettokenKeys && typeof data.data === 'string';
};

/**
 * @param {object} json Nettoken exported JSON Object.
 * @param {Map} credentialsDomainMap Existing credentials sorted by domain.
 * @param {array} groupNames Existing group names.
 * @param {function} t
 *
 * @returns {object} See `formatResponse()` for signature.
 */
const processV1 = (json, credentialsDomainMap, groupNames, t) => {
  if (!Array.isArray(json.groups)) throw new Error(t('modal.importPreview.corruptedFile'));

  const credentialFilter = CredentialFilter(credentialsDomainMap);
  const groupFilter = GroupFilter(groupNames);
  let credentials = new List();
  let emptyGroups = new List();

  json.groups.forEach(jsonGroup => {
    if (!Array.isArray(jsonGroup.credentials)) jsonGroup.credentials = [];

    let groupId = jsonGroup.name;
    if (groupId) {
      groupId = decodeURIComponent(escape(groupId));
    }
    jsonGroup.credentials.forEach(obj => {
      const { domain } = obj;
      if (obj.password) {
        obj.password = decodeURIComponent(escape(obj.password));
      }
      if (!domain) return;
      const credential = new Credential({
        domain,
        email: obj.email,
        groupId,
        loginUrl: obj.loginUrl,
        name: obj.name,
        note: obj.memo,
        password: obj.password,
        phone: obj.phone,
        username: obj.username || obj.phone,
      });
      credentials = credentialFilter(credential, true);
    });

    emptyGroups = groupFilter(groupId, credentials);
  });

  return { credentials, emptyGroups };
};

/**
 * @param  {...any} args See `processArchive()` for list of arguments.
 *
 * @returns {object} Null if version not supported.
 */
const processFile = (...args) => {
  switch (args[4]) {
    case '1.0':
      return processV1(...args);
    default:
      return null;
  }
};

export default {
  isSupportedFile,
  name: 'Nettoken',
  processFile,
};
